import { request, oldServiceName } from './base-config';

//获取今日-昨日 所有车辆 入场-出场 的统计
export function getVehicleCountByTime(data) {
  return request(`${oldServiceName}/screen/vehicle/getAllVehicleCountByTime`, {
    method: 'GET',
    body: data,
  });
}

//【大屏-（3）车辆进出场统计】进出时间-设备ID筛选
export function getVehicleTrendByTime(data) {
  return request(
    `${oldServiceName}/screen/vehicle/vehicleLaneStatisticsRecord`,
    {
      method: 'GET',
      body: data,
    },
  );
}

//获取当前在场车辆统计
export function getVehiclePresentVehicles(data) {
  return request(`${oldServiceName}/screen/vehicle/presentAllVehiclesCount`, {
    method: 'GET',
    body: data,
  });
}

//【今日车辆清洗】车辆进场清洗统计数量

export function getVehicleWashCount(data) {
  return request(`${oldServiceName}/screen/vehicle/getVehicleWashCount`, {
    method: 'GET',
    body: data,
  });
}

//车辆进出场记录
export function getVehicleAccessRecord(data) {
  return request(`${oldServiceName}/screen/vehicle/vehicleAccessRecord`, {
    method: 'GET',
    body: data,
  });
}

//【大屏-获取车辆最近的清洗记录】
export function getLastWashRecord(data) {
  return request(`${oldServiceName}/screen/vehicle/getLastWashRecord`, {
    method: 'GET',
    body: data,
  });
}

//【大屏-弹窗-分页查询车辆的清洗详情记录】
export function getPageWashRecordLists(data) {
  return request(`${oldServiceName}/screen/vehicle/pageWashRecord`, {
    method: 'POST',
    body: data,
  });
}

/**
 * 根据设备类型查询设备的列表
 * @param {object} data
 * @param {string} data.deviceGroup
 * @param {string} data.idxProjectId
 * @returns
 */
export function getDeviceLists(data) {
  return request(
    `${oldServiceName}/screen/search/deviceList/${data.deviceGroup}`,
    {
      method: 'GET',
      body: data,
    },
  );
}

//【大屏-车辆进出记录】获取最近5条数据
export function getLastRecordCarLists(data) {
  return request(`${oldServiceName}/screen/vehicle/getLastRecord`, {
    method: 'GET',
    body: data,
  });
}

//今日昨日及车辆在场总数
export function getYesAndTodayVehiclesCount(data) {
  return request(`${oldServiceName}/screen/vehicle/vehiclesCount`, {
    method: 'GET',
    body: data,
  });
}
