import { Vue, Component, Prop } from 'vue-property-decorator';
import Konva from 'konva';
import { getPlanData } from '@/services/device-manage/device';
import { UploadService } from '@triascloud/services';
import { Emitter } from '../../components/screen-utils';
import store from '@/store';

@Component
export default class PlanViewCanvasMixin extends Vue {
  @Prop({ type: Boolean, default: false }) isRotation; // 是否需要旋转
  @Prop({ type: Boolean, default: false }) isModal; // 是否需要旋转

  background = '';
  get projectId() {
    return store.state.screen.projectId;
  }

  /**@readonly */
  canvasWidth = 0;
  /**@readonly */
  canvasHeight = 0;
  ratio = 16 / 9;
  /**@type { Konva.Stage } */
  stage = null;
  tr = null;
  layer = null;
  image = null;
  defaultRatio = 16 / 9;

  // 获取平面图
  planViewData = {};
  async getPlanViewData() {
    this.planViewData = await getPlanData(this.projectId, this.deviceGroup);
  }

  async initData() {
    await this.getPlanViewData();
    const src = this.planViewData.planUrl
      ? await this.getPicUrl(this.planViewData.planUrl)
      : '';
    this.image = new Image();
    this.image.src = src;
    this.image.onload = () => {
      this.background = src;
      this.importData(this.planViewData.planData);
      this.setRotation();
    };
  }

  async getPicUrl(ossPath) {
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    const picUrl = await customService.getAuth(ossPath);
    return picUrl;
  }

  // 设置平面图旋转角度
  async setRotation() {
    if (!this.isRotation) return;
    await this.getPlanViewData();
    const groups = this.stage.find('.DeviceItem');
    const rotationAngleMap = new Map();
    this.planViewData.deviceCurrentStatusBOList.forEach(item => {
      rotationAngleMap.set(item.idxDeviceId, item.rotationAngle ?? 0);
    });
    groups.forEach(group => {
      const rotation = rotationAngleMap.get(group.attrs?.data?.deviceId) ?? 0;
      let tween = new Konva.Tween({
        node: group,
        rotation,
        duration: 3,
        easing: Konva.Easings.EaseInOut,
        onFinish: () => {
          tween.destroy();
          tween = null;
        },
      });
      tween.play();
    });
  }

  importData(json) {
    if (!json) return;
    // 更新tooltip
    function updateTooltip(tooltip, x, y, text) {
      tooltip.getText().text(text);
      tooltip.position({
        x,
        y,
      });
      tooltip.show();
    }

    // 创建tooltip
    function createTooltip() {
      /**@type { Konva.Layer } */
      const tooltipLayer = new Konva.Layer({
        name: 'tooltipLayer',
      });
      const tooltip = new Konva.Label({
        opacity: 0.75,
        visible: false,
        listening: false,
      });

      tooltip.add(
        new Konva.Tag({
          fill: 'black',
          pointerDirection: 'down',
          pointerWidth: 10,
          pointerHeight: 10,
          lineJoin: 'round',
          shadowColor: 'black',
          shadowBlur: 10,
          shadowOffsetX: 10,
          shadowOffsetY: 10,
          shadowOpacity: 0.5,
        }),
      );

      tooltip.add(
        new Konva.Text({
          text: '',
          fontFamily: 'Calibri',
          fontSize: 25,
          padding: 5,
          fill: 'white',
        }),
      );

      tooltipLayer.add(tooltip);

      return { tooltipLayer, tooltip };
    }

    this.stage = Konva.Node.create(json, this.$refs.konvaRef);
    const { scale } = this.resizeStage();
    const { tooltipLayer, tooltip } = createTooltip();
    this.stage.add(tooltipLayer);

    // 判断node节点中name是否包含Group，后台部分设置的name规则是Group + id
    const groups = this.stage.find('.DeviceItem');
    const layers = this.stage.getLayers();
    const shapeLayer = layers.find(node => node.attrs.name === 'shapeLayer');
    shapeLayer.find('Text').forEach(node => {
      node.visible(false);
    });

    // 给Group设置监听事件，显示tooltip
    groups.forEach(node => {
      node.on('mousemove', e => {
        const shape = e.target;
        const mousePos = this.stage.getPointerPosition();
        const x = mousePos.x / scale;
        const y = mousePos.y / scale - 5;
        updateTooltip(tooltip, x, y, shape.getParent().attrs.data.deviceName);
        tooltipLayer.batchDraw();
      });

      node.on('mouseout', () => {
        tooltip.hide();
        tooltipLayer.draw();
      });

      node.setAttrs({
        draggable: false,
      });
    });
  }

  // 根据屏幕大小不同，缩放重绘
  resizeStage() {
    const { width, height } = this.planViewData.planData.attrs;
    this.ratio = width / height;
    this.computedCanvasSize();
    const scale = this.canvasWidth / width;
    this.stage.width(this.canvasWidth);
    this.stage.height(this.canvasHeight);
    this.stage.scale({ x: scale, y: scale });
    this.stage.draw();
    return { scale };
  }

  // 根据外部容器大小计算画布大小（完整展示）
  computedCanvasSize() {
    const {
      width,
      height,
    } = this.$refs.konvaRef.parentElement.getBoundingClientRect();
    const boxRatio = width / height;
    this.canvasWidth = width;
    this.canvasHeight = width / this.ratio;
    if (this.ratio < boxRatio) {
      this.canvasWidth = height * this.ratio;
      this.canvasHeight = height;
    }
  }

  resize() {
    setTimeout(this.resizeStage);
  }

  async mounted() {
    await this.$nextTick();
    setTimeout(this.initData, this.isModal ? 300 : 0);
    document.addEventListener('fullscreenchange', this.resize);
    Emitter.$on('refresh', this.setRotation);
  }

  beforeDestroy() {
    document.removeEventListener('fullscreenchange', this.resize);
    Emitter.$off('refresh', this.setRotation);
  }
}
