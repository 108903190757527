<template>
  <a-range-picker
    :show-time="{
      defaultValue: [
        $moment('00:00:00', 'HH:mm:ss'),
        $moment('23:59:59', 'HH:mm:ss'),
      ],
    }"
    :class="$style.timeSelect"
    v-on="$listeners"
    v-bind="$attrs"
    format="YYYY-MM-DD HH:mm:ss"
    :disabled-date="disabledDate"
    @calendarChange="calendarPriceRangeChange"
  />
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component()
export default class RangePicker extends Vue {
  @Prop({ type: Number, default: 31 }) daysInterval; // 可选天数区间

  selectPriceDate = '';
  calendarPriceRangeChange(date) {
    this.selectPriceDate = date[0];
  }
  disabledDate(current) {
    const DAY = 24 * 60 * 60 * 1000;
    const selectDate = this.$moment(this.selectPriceDate).valueOf();
    const offsetDays = this.daysInterval * DAY;
    // 禁用选中时间前后的{{offsetDays}}天
    return (
      this.$moment(current).valueOf() > selectDate + offsetDays ||
      this.$moment(current).valueOf() < selectDate - offsetDays ||
      current > this.$moment().endOf('day')
    );
  }
}
</script>

<style lang="less" module>
.timeSelect {
  width: 1.8rem !important;
  margin-top: 0.03rem;
}
</style>
